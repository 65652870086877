import { Tooltip, CircularProgress } from '@mui/material';
import React, { memo, useCallback } from 'react';
import ReactMarkdown from "react-markdown";
import _ from 'lodash';
import gfm from "remark-gfm";
import { useMemo } from 'react';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { TradeGPTBotIconDark, TradeGPTBotIconLight } from '../MyComponent/Icons';
import rehypeRaw from "rehype-raw";
import TypingAnimation from '../MyComponent/TypingAnimation';
import Highcharts from "highcharts";
import "highcharts/modules/stock";
import "highcharts/modules/drag-panes";
import "highcharts/modules/annotations-advanced";
import "highcharts/modules/price-indicator";
import "highcharts/modules/full-screen";
import "highcharts/modules/stock-tools";
import HighchartsReact from "highcharts-react-official";
import depthChartOptions from "../assets/JSON/depthChartOptions.json";
import stockChartLight from "../assets/JSON/stockChartOptionsLight.json";
import stockChartDark from "../assets/JSON/stockChartOptionsDark.json";
import lineChartOptions from "../assets/JSON/lineChart.json";
import barChartOptions from "../assets/JSON/barChart.json";
import { DataGrid } from "@mui/x-data-grid";
const formatNumericValue = (value) => {
  if (value >= 1_000_000_000_000) {
    return `${(value / 1_000_000_000_000).toFixed(2)}T`;
  }
  else if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(2)}B`;
  }
  else if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(2)}M`;
  }
  else {
    const decimalPart = value.toString().split(".")[1];
    if (decimalPart && decimalPart.length >= 1) {
      return value.toFixed(2);
    }
  }
  return value.toString();
};
const renderers = {
  a: ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
};
const containsLaTeX = text => /\\\[|\\\(|```latex/.test(text);

const ChatMessage = memo(function ChatMessage({ fullscreen, message, loading = false, showActions = false, darkTheme = false, onCopy, onLike, onDislike }) {
  const hasCharts = useMemo(() => !!message?.chart_data?.length, [message?.chart_data]);
  const chartData = useMemo(() => message?.chart_data || [], [message?.chart_data]);
  // Define handlers inside ChatMessage
  const handleCopyClick = useCallback(() => {
    onCopy(message.output);
  }, [onCopy, message.output]);

  const handleLikeClick = useCallback(() => {
    onLike(message);
  }, [onLike, message]);

  const handleDislikeClick = useCallback(() => {
    onDislike(message);
  }, [onDislike, message]);

  const renderContent = (output) => {
    if (containsLaTeX(output)) {
      return <TypingAnimation text={output} components={renderers} />;
    }
    return (
      <div>
        <ReactMarkdown
          remarkPlugins={[gfm]}
          rehypePlugins={[rehypeRaw]}
          components={renderers}
          className={`markdown-body ${fullscreen ? '' : 'max-w-3'} ${!message?.isBot ? "chat-bubble" : ''}`}
        >
          {output}
        </ReactMarkdown>
        {hasCharts && chartData.map((item, chartIndex) => {
          const { chart_type } = item;
          if (chart_type === "line-depth") {
            const {
              data_below = [],
              data_above = [],
              current_price = 0,
              title = "Default Title",
            } = item;
            const options = _.cloneDeep(depthChartOptions);
            options.series = [
              {
                name: "Puts",
                data: [...data_below],
                color: "#fc5857",
              },
              {
                name: "Calls",
                data: [...data_above],
                color: "#03a7a8",
              },
            ];
            options.xAxis.plotLines = [
              {
                color: "#888",
                value: current_price,
                width: 1,
                label: {
                  text: "Actual price",
                  rotation: 90,
                },
              },
            ];
            options.title.text = title;
            return (
              <div
                key={`${chartIndex}-depth-chart`}
                id={`depthChartContainer-${chartIndex}`}
                style={{ width: "100%", height: "400px" }}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                />
              </div>
            );
          }
          if (chart_type === "bar-2") {
            const {
              categories = [],
              data = {},
              mainTitle = "Default Main Title",
              xAxisTitle = "X-Axis Title",
              yAxisTitle = "Y-Axis Title",
            } = item;
            const options = JSON.parse(
              JSON.stringify(barChartOptions),
            );

            options.title.text = mainTitle;
            options.xAxis.categories = categories;
            options.xAxis.title.text = xAxisTitle;
            options.yAxis.title.text = yAxisTitle;

            // Transform the data to fit Highcharts format
            options.series = Object.keys(data).map(key => ({
              name: key,
              data: _.cloneDeep(data[key] || []), // Deep copy if data structure is complex
            }));

            return (
              <div className="barz" id="highcharts-container" key={chartIndex}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                />
              </div>
            );
          }
          if (chart_type === "price-candle") {
            const {
              chart_title = "Default Chart Title",
              data = {},
            } = item;
            const options = darkTheme
              ? JSON.parse(JSON.stringify(stockChartDark))
              : JSON.parse(JSON.stringify(stockChartLight));
            const series = [];

            Object.keys(data).forEach((ticker) => {
              const tickerData = (data[ticker] || []).map(d => [
                d[0],
                d[1],
                d[2],
                d[3],
                d[4],
              ]);
              series.push({
                type: "candlestick",
                name: ticker,
                data: tickerData,
                tooltip: {
                  valueDecimals: 2,
                },
              });
            });

            options.title.text = chart_title;
            options.series = series;

            return (
              <div
                key={`highstock-${chart_title}`}
                id="highcharts-container"
              >
                <HighchartsReact
                  key={chartIndex}
                  highcharts={Highcharts}
                  constructorType="stockChart"
                  options={options}
                />
              </div>
            );
          }
          if (chart_type === "price-line") {
            const {
              chart_title = "Default Chart Title",
              data = {},
              ticker = [],
            } = item;
            const options = JSON.parse(
              JSON.stringify(lineChartOptions),
            );
            const series = [];

            ticker.forEach((tickerName) => {
              const seriesData = (data[tickerName] || []).map(
                point => [...point],
              );

              const seriesObj = {
                type: "line",
                name: tickerName,
                data: seriesData,
              };
              series.push(seriesObj);
            });

            if (series.length > 1) {
              options.plotOptions.series = {
                compare: "percent",
              };
              options.tooltip = {
                shared: true,
                split: true,
                crosshairs: true,
                pointFormatter: function () {
                  const change = this.change;
                  return (
                    "<span style=\"color:"
                    + this.series.color
                    + "\">"
                    + this.series.name
                    + "</span>: <b>"
                    + Highcharts.numberFormat(change, 2, ".")
                    + "%</b><br/>"
                  );
                },
                valueDecimals: 2,
                backgroundColor: "rgb(0 54 143)",
                borderColor: "rgb(0 54 143)",
                style: {
                  color: "#FFF",
                  fontSize: "12px",
                },
              };
            }

            options.title.text = chart_title;
            options.series = series;
            options.yAxis.title.text = "Price";
            options.xAxis.title = {
              text: "Date",
            };

            return (
              <div key={chartIndex} id="highcharts-container">
                <HighchartsReact
                  key={chartIndex}
                  highcharts={Highcharts}
                  constructorType="stockChart"
                  options={options}
                />
              </div>
            );
          }
          if (chart_type === "line") {
            const {
              chart_title = "Default Chart Title",
              data = {},
              yAxisTitle = "Y-Axis Title",
              xAxisTitle = "X-Axis Title",
              keys = [],
            } = item;
            const options = JSON.parse(
              JSON.stringify(lineChartOptions),
            );
            const allSeries = Object.keys(data);
            const series = [];

            allSeries.forEach((seriesName) => {
              const seriesData = (data[seriesName] || []).map(
                (value, index) => {
                  return [
                    new Date(keys[index] || Date.now()).getTime(),
                    value,
                  ];
                },
              );

              const seriesObj = {
                type: "line",
                name: seriesName,
                data: seriesData,
              };
              series.push(seriesObj);
            });

            options.title.text = chart_title || "Default Title";
            options.series = series;
            options.yAxis.title.text
              = yAxisTitle || "Y-Axis Title";
            options.xAxis.title = {
              text: xAxisTitle || "X-Axis Title",
            };

            if (series.length > 1) {
              options.plotOptions.series = {
                compare: "percent",
              };
              options.tooltip = {
                shared: true,
                split: true,
                crosshairs: true,
                pointFormatter: function () {
                  const change = this.change;
                  return (
                    "<span style=\"color:"
                    + this.series.color
                    + "\">"
                    + this.series.name
                    + "</span>: <b>"
                    + Highcharts.numberFormat(change, 2, ".")
                    + "%</b><br/>"
                  );
                },
                valueDecimals: 2,
                backgroundColor: "rgb(0 54 143)",
                borderColor: "rgb(0 54 143)",
                style: {
                  color: "#FFF",
                  fontSize: "12px",
                },
              };
            }

            return (
              <div key={chartIndex} id="highcharts-container">
                <HighchartsReact
                  key={chartIndex}
                  highcharts={Highcharts}
                  constructorType="stockChart"
                  options={options}
                />
              </div>
            );
          }
          if (chart_type === "bar") {
            const {
              chart_title = "Default Chart Title",
              data = {},
              xAxisTitle = "X-Axis Title",
              yAxisTitle = "Y-Axis Title",
              keys = [],
            } = item;
            const options = JSON.parse(
              JSON.stringify(barChartOptions),
            );
            // Initialize an empty array for series
            const seriesData = [];
            // Iterate over each key in the data object
            for (const key in data) {
              if (
                Object.prototype.hasOwnProperty.call(data, key)
              ) {
                seriesData.push({
                  name: key,
                  data: data[key] || [],
                });
              }
            }
            options.title.text = chart_title;
            options.xAxis.categories = keys;
            options.xAxis.title.text = xAxisTitle;
            options.yAxis.title.text = yAxisTitle;
            options.series = seriesData; // Assign the prepared series data
            options.tooltip.formatter = function () {
              return `<b>${this.x}</b><br/><span style="color:${this.series.color
                }">${this.series.name
                }</span>: <b>${Highcharts.numberFormat(
                  this.y,
                  0,
                )}</b>`;
            };
            return (
              <div
                key={chart_title}
                id="highcharts-container"
                className="barz"
              >
                <HighchartsReact
                  key={chart_title}
                  highcharts={Highcharts}
                  constructorType="chart"
                  options={options}
                />
              </div>
            );
          }
          if (chart_type === "table") {
            const { headers = [], rows = [] } = item;

            const rowData = rows.map((arr, index) => {
              const obj = { id: index };
              arr.forEach((item, key) => {
                obj[key] = item;
              });
              return obj;
            });
            const columns = headers.map(
              (header, index) => {
                const column = {
                  field: `${index}`,
                  headerName: header,
                  sortable: true,
                  valueFormatter: (params) => {
                    const value = params;
                    if (typeof value === "number") {
                      return formatNumericValue(value);
                    }
                    return value;
                  },
                };
                if (fullscreen) {
                  column.flex = 1;
                } else {
                  column.width = 125
                }
                return column;
              },
            );

            return (
              <div key={chartIndex} className="">
                <DataGrid
                  columns={columns}
                  rows={rowData}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  disableRowSelectionOnClick
                  pageSizeOptions={[10, 25, 50, 100]}
                  sortingOrder={["asc", "desc"]}
                  sx={{
                    "& .MuiDataGrid-columnHeader": {
                      backgroundColor: darkTheme
                        ? "#343541"
                        : "white",
                    },
                    "& .MuiDataGrid-filler": {
                      backgroundColor: darkTheme
                        ? "#343541"
                        : "white",
                    },
                  }}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    );
    // }
  };
  return (
    <>
      <div className="w-full text-token-text-primary">
        <div className="justify-center text-base md:gap-6 m-auto">
          <div className={`flex flex-1 text-base mx-auto gap-3 w-full ${!message?.isBot ? 'flex-reverse' : ''}`}>
            {
              message?.isBot && (
                <div className="flex-shrink-0 flex flex-col relative items-end">
                  <div className="pt-0.5">
                    <div className="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                      <div className="relative flex">
                        {(darkTheme ? <TradeGPTBotIconLight width={24} height={24} /> : <TradeGPTBotIconDark width={24} height={24} />)}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            <div className={`relative flex w-full flex-col ${message?.isBot ? 'custom-tradegpt-widget-message-content' : 'flex-end'}`}>
              {!!message?.isBot && (
                <div className={`tradegpt-widget-username tradegpt-widget-bot-name`}>TradeGPT</div>
              )}
              <div className={`flex-col gap-1 md:gap-3 ${!message?.isBot ? 'text-right' : ''}`}>
                <div className="flex flex-grow flex-col max-w-full">
                  <div data-message-author-role="user" className="min-h-[20px] overflow-y-hidden break-words overflow-x-auto flex flex-col gap-3 tradegpt-widget__content__message__text">
                    <div className='markdown-body'>
                      {loading && !message.outputLoading ? (
                        <div style={{ marginLeft: '10px' }} className="flex items-center space-x-2 mt-1">
                          <CircularProgress size={20} style={{ color: '#A6A6A6' }} />
                          <span className="text-sm text-token-text-secondary">Analyzing ...</span>
                        </div>
                      ) : renderContent(message.output)}
                    </div>
                    {
                      message?.isBot && !message.outputLoading && (
                        <div className='tradegpt-widget-message-actions'>
                          {onCopy &&
                            <Tooltip title="Copy">
                              <button onClick={handleCopyClick} className={`tradegpt-widget-message-action ${!showActions ? 'invisible-button' : ''}`}>
                                <ContentPasteIcon />
                              </button>
                            </Tooltip>
                          }
                          {onLike && onDislike &&
                            <>
                              {onLike &&
                                <Tooltip title="Good response">
                                  <button onClick={handleLikeClick} className={`tradegpt-widget-message-action ${!showActions ? 'invisible-button' : ''}`}>
                                    <ThumbUpOffAltIcon />
                                  </button>
                                </Tooltip>
                              }
                              {onDislike &&
                                <Tooltip title="Bad response">
                                  <button onClick={handleDislikeClick} className={`tradegpt-widget-message-action ${!showActions ? 'invisible-button' : ''}`}>
                                    <ThumbDownOffAltIcon />
                                  </button>
                                </Tooltip>
                              }
                            </>
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default ChatMessage
