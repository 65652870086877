import React, { useMemo, useState } from "react";
import StarterPlan from "../OptionsMentorship/Plans/StarterPlan";
import IntermediatePlan from "../OptionsMentorship/Plans/IntermediatePlan";
import AdvancedPlan from "../OptionsMentorship/Plans/AdvancedPlan";
import PlatinumPlan from "../OptionsMentorship/Plans/PlatinumPlan";
import "./index.scss";
import { useAuth } from "../../contexts/Auth";
import { Modal } from "@mui/material";
import ContactForm from "../EmeraldSignup/ContactForm";
import { connect } from "react-redux";
import moment from "moment";

const MentorshipPaywellConfirm = ({ userSettings }) => {
  const [product_type, setProduct_type] = useState("");
  // product types: 'PLATINUM STANDARD', OPTIONS MENTORSHIP INTERMEDIATE, OPTIONS MENTORSHIP ADVANCED, OPTIONS MENTORSHIP STARTER 'OPTIONS MENTORSHIP PLATINUM', "EMERAL INCOME ALERTS"
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const {
    hasOptionsMentorshipStarter,
    hasPlatinumProgram,
    hasOptionsMentorshipIntermediate,
    hasOptionsMentorshipAdvanced,
    loading,
  } = useAuth();

  const {
    hasSubmittedomPlatinum,
    hasSubmittedomIntermediate,
    hasSubmittedomAdvanced,
  } = useMemo(() => {
    const currentDate = moment();
    const contactDates = {
      hasSubmittedomPlatinum: userSettings?.omPlatinumContact,
      hasSubmittedomIntermediate: userSettings?.omIntermediateContact,
      hasSubmittedomAdvanced: userSettings?.omAdvancedContact,
    };
    return Object.entries(contactDates).reduce((acc, [key, value]) => {
      acc[key] = value
        ? currentDate.isBefore(moment(value).add(1, "week"))
        : false;
      return acc;
    }, {});
  }, [userSettings, loading]);

  const openModal = (product) => {
    setProduct_type(product);
    setOpen(true);
  };

  return (
    <div className="mentorship-paywell-confirm-page">
      <Modal open={open}>
        <ContactForm product_type={product_type} onClose={handleClose} />
      </Modal>
      <div className="mentorship-paywell-confirm-page-title">
        TradeAlgo Options Mentorship
      </div>
      <div className="mentorship-paywell-confirm-page-subtitle">
        Launch your trading journey and execute consistently profitable trades
        with the TradeAlgo <br /> Options Mentorship.
      </div>
      <div className="mentorship-paywell-confirm-page-options">
        <div className="mentorship-paywell-confirm-page-options-title">
          Chose Your Plan:{" "}
        </div>

        <div className="mentorship-paywell-confirm-page-options-basic-title">
          Basic Options Mentorship Plans
        </div>
        <div className="mentorship-paywell-confirm-page-options-basic-subtitle mb-23">
          Find the mentorship plan that will suit your needs and level of
          trading knowledge below and <br /> kickstart your trading journey.
        </div>

        <div className="mentorship-paywell-confirm-page-option-row">
          {!hasOptionsMentorshipStarter && <StarterPlan />}
          {!hasOptionsMentorshipIntermediate && (
            <IntermediatePlan
              hasSubmitted={hasSubmittedomIntermediate}
              openModal={openModal}
            />
          )}
          {!hasOptionsMentorshipAdvanced && (
            <AdvancedPlan
              hasSubmitted={hasSubmittedomAdvanced}
              openModal={openModal}
            />
          )}
        </div>
        {!hasPlatinumProgram && (
          <>
            <div className="mentorship-paywell-confirm-page-options-plat-title mb-7">
              Platinum Mentorship Plans
            </div>
            <div className="mentorship-paywell-confirm-page-options-basic-subtitle mb-7">
              Personalized coaching and equipping the users with all the key
              resources and erudition required to comprehend the essential{" "}
              <br /> concepts and rules of options trading. The program has 52
              one-on-one meetings, along with complete access to the <br /> all
              the Platinum program with its own live stream and the sought-after
              Platinum Alerts.
            </div>
            <div className="mentorship-paywell-confirm-page-options-basic-subtitle mb-23">
              Platinum Membership plans will consist of everything that is
              available in the{" "}
              <span className="blue-txt">Basic Options Mentorship</span> and{" "}
              <span className="blue-txt"> Platinum Program</span>.
            </div>

            <PlatinumPlan
              hasSubmitted={hasSubmittedomPlatinum}
              openModal={openModal}
            />
          </>
        )}
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

export default connect(stateToProps, null)(MentorshipPaywellConfirm);
