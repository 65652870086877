import React from "react";
import Button from "@mui/material/Button";
import "./plans.scss";
import { connect } from "react-redux";
import { useAuth } from "../../../contexts/Auth";
import { CircularProgress } from "@material-ui/core";

const IntermediatePlan = ({ userSettings, openModal, hasSubmitted }) => {
  const { hasOptionsMentorshipIntermediate, loading } = useAuth();
  const processing =
    !!userSettings?.om_intermediate_sign_up ||
    !!userSettings?.om_intermediate_renewed ||
    hasOptionsMentorshipIntermediate ||
    hasSubmitted;
  return (
    <div className="subscribe-plan-item intermediate-item">
      <div className="subscribe-plan-item-title">
        TradeAlgo Options
        <br />
        Mentorship:{" "}
        <span className="subscribe-plan-item-type">Intermediate</span>
      </div>
      <div className="subscribe-plan-item-includes-title">Includes</div>
      <ul className="subscribe-plan-item-includes-entries">
        <li className="subscribe-plan-item-includes-entry">
          10 sessions over 6 months
        </li>
        <li className="subscribe-plan-item-includes-entry">
          Options trading pro-led personalized coaching
        </li>
      </ul>
      <div className="subscribe-plan-item-price">
        <span className="subscribe-plan-item-small-text">USD</span> $2,450
      </div>
      <Button
        sx={{ width: processing ? "215px !important" : "" }}
        disabled={processing || loading}
        className="subscribe-plan-item-button"
        onClick={() => openModal("OPTIONS MENTORSHIP INTERMEDIATE")}
      >
        {loading ? (
          <CircularProgress color="success" size={24} />
        ) : processing ? (
          "Access Requested"
        ) : (
          "Contact Us"
        )}
      </Button>
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

export default connect(stateToProps, null)(IntermediatePlan);
