import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const CONTACT_FORM_LOAD_TOP = "CFM/LOAD/TOP";
export const CONTACT_FORM_LOAD_TOP_SUCCESS = "CFM/LOAD/TOP/SUCCESS";
export const CONTACT_FORM_LOAD_TOP_FAILURE = "CFM/LOAD/TOP/FAILURE";

// Action Creators
export const contactFormLoad = (formData) => ({
  type: CONTACT_FORM_LOAD_TOP,
  formData,
});
export const contactFormLoadSuccess = (data) => ({
  type: CONTACT_FORM_LOAD_TOP_SUCCESS,
  data,
});
export const contactFormLoadFailure = (error) => ({
  type: CONTACT_FORM_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchContact(action) {
  try {
    const { formData } = action;
    const { name, email, phone, product_type } = formData;
    const body = {
      name,
      email,
      phone,
      product_type,
    };
    const { status } = yield axios.post(
      `${apiURL}/subscriptions/request-access`,
      body,
      { withCredentials: true },
    );
    yield put(contactFormLoadSuccess(status));
  } catch (error) {
    yield put(contactFormLoadFailure(error));
  }
}

function* listenDocusign() {
  yield takeEvery(CONTACT_FORM_LOAD_TOP, fetchContact);
}

// Root Saga
export function* saga() {
  yield all([fork(listenDocusign)]);
}

const INIT_STATE = {
  status: "",
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONTACT_FORM_LOAD_TOP_SUCCESS:
      return {
        ...state,
        success: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
