import React, { useEffect, useMemo, useState } from "react";
import { LicenseInfo } from "@mui/x-license";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import Routes from "./routes/index";
import { Helmet } from "react-helmet";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { themeSetter } from "./appRedux/ducks/themeMode";
import { layoutSetter } from "./appRedux/ducks/layoutMode";
import { connect } from "react-redux";
import { cleanUnreadMessageCount, socketListen } from "./appRedux/ducks/socket";
import WidgetWrapper from "./components/TradingView/WidgetWrapper";
import { useAuth } from "./contexts/Auth";
import logoBlack from "./assets/images/logo-black.png";
import chatIcon from "./assets/images/chat-icon.png";
import tgptArrow from "./assets/images/tgpt-arrow.png";
import tgptArrowWhite from "./assets/images/stroked.png";
import logoWhite from "./assets/images/logo.png";
// import { chatParticipantsLoad } from "./appRedux/ducks/chatParticipants";
import { liveChatSetter } from "./appRedux/ducks/hideLiveChat";
// import DocusignModal from './components/modals/DocusignModal/index'
import { useMedia } from "react-media";
import InvestorSurvey from "./components/homePage/InvestorSurvey";
import { SendBirdProvider } from "sendbird-uikit";
import Joyride from "react-joyride";
import {
  homePageSteps,
  joyrideStyles,
} from "./components/common/Joyride/JoyrideHelpers";
import { symbolSetter } from "./appRedux/ducks/symbol";
import WelcomeModal from "./components/homePage/WelcomeModal";
import { addUserLoad } from "./appRedux/ducks/addUsername";
import moment from "moment";
import { addUserSettingsLoad } from "./appRedux/ducks/userSettings";
import { runJourneySetter } from "./appRedux/ducks/runJourney";
import { Badge } from "@material-ui/core";
import Conversation from "./components/TradingView/Conversation";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
LicenseInfo.setLicenseKey(
  "660bc7e55c831c1e2d6db9adbbf641e0Tz05NTgzMixFPTE3NTQ3NTQ5MjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==",
);

const options = {
  autoConfig: true,
  debug: true,
};

const fbq = ReactPixel;
const channelUrl = process.env.REACT_APP_SENDBIRD_OPEN_CHANNEL_URL; // TA: 'sendbird_open_channel_12232_c4f329285fa22fe2280c1a08ebde56b0baeb42c9'
const appId = process.env.REACT_APP_SENDBIRD_APP_ID; // TA: 'ABE805E6-EEA2-4FAE-9606-0481DD2CFD70'
const App = ({
  setUserSettings,
  showJourney,
  setShowJourney,
  userSettings,
  addUsername,
  darkflowTable,
  themeMode,
  themeToggle,
  layoutMode,
  layoutToggle,
  channels,
  listen,
  unreadMessageCount,
  cleanCount,
  hideChat,
  setHideChat,
  liveChatExpanded,
  setSymbol,
}) => {
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [showWidget, setShowWidget] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [run, setRun] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [stringSet] = useState({
    PLACE_HOLDER__NO_CHANNEL: "",
  });
  const { user, userData, authUser, canSeeInternal } = useAuth();
  const { pathname } = useLocation();
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const canViewWidget =
    canSeeInternal &&
    !pathname.includes("signin") &&
    !pathname.includes("signup") &&
    !pathname.includes("mobile") &&
    !hideChat;
  const canViewChat =
    canSeeInternal &&
    !showChat &&
    !pathname.includes("live-chat") &&
    !pathname.includes("signin") &&
    !pathname.includes("options-academy") &&
    !pathname.includes("signup") &&
    !pathname.includes("basics-training") &&
    !pathname.includes("advanced-training") &&
    !pathname.includes("live-walkthrough") &&
    !pathname.includes("market-analysis") &&
    !pathname.includes("tutorials") &&
    !pathname.includes("mobile") &&
    !hideChat;

  useEffect(() => {
    if (
      !!userData &&
      pathname !== "/signout" &&
      pathname !== "/signout" &&
      !channels.includes("CHAT") &&
      !pathname.includes("live-options") &&
      !pathname.includes("company")
    ) {
      listen(["CHAT", "TRADE_MESSAGE"]);
    }
  }, [pathname, channels, userData]);
  const setJourneyViewed = () => {
    setUserSettings(
      {
        ...userSettings,
        journey_completed: true,
      },
      false,
      true,
    );
  };
  function JourneyCallbacks({ action, index, type }) {
    if (type === "tour:end") {
      setJourneyViewed();
      setRun(false);
    }
    if (action === "next" && index === 3 && type === "step:after") {
      const sorted = darkflowTable?.trending_up.sort((a, b) => {
        const price = a.last_price;
        let performance1 = Math.round((price / a.trend_start - 1) * 1000) / 10;
        const price2 = b.last_price;
        let performance2 = Math.round((price2 / b.trend_start - 1) * 1000) / 10;
        return performance2 - performance1;
      });
      const currTicker = sorted[0]?.ticker;

      setSymbol({
        type: "",
        value: currTicker,
      });
      history.push(`/company/${currTicker}`);
    }
    if (action === "next" && index === 8 && type === "step:after") {
      history.push(`/alerts/Auto/Up`);
    }
    if (action === "next" && index === 11 && type === "step:after") {
      history.push(`/live-chat/real-time-trading-alerts`);
    }
    if (action === "next" && index === 16 && type === "step:after") {
      history.push(`/market-analysis`);
    }
    if (action === "next" && index === 19 && type === "step:after") {
      history.push(`/home/Intraday/Auto/Up`);
    }
  }
  const nickName = useMemo(() => {
    return userData ? userData?.display_name || "" : "";
  }, [JSON.stringify(userData)]);

  useEffect(() => {
    fbq.init("876551646440035", options);
    ReactPixel.init("1091661061298376", options);
  }, []);

  useEffect(() => {
    const utmParams = new URLSearchParams(location.search);
    localStorage.setItem("utm_source", utmParams.get("utm_source"));
    localStorage.setItem("utm_campaign", utmParams.get("utm_campaign"));
    localStorage.setItem("utm_term", utmParams.get("utm_term"));
    localStorage.setItem("utm_content", utmParams.get("utm_content"));
  }, []);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 403) {
        history.push("/signout");
      }

      return Promise.reject(error);
    },
  );
  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
        },
      },
      MuiPopover: {
        paper: {
          background: themeMode === "dark" ? "#000" : "white",
        },
      },
    },
    palette: {
      palette: {
        type: themeMode,
      },
      primary: {
        // background-color: rgba(25, 27, 28, 0.5);
        // color: rgb(201, 194, 186);

        // light: will be calculated from palette.prmary.main,
        main: themeMode === "dark" ? "#001128" : "#0i07bff",
        table: themeMode === "dark" ? "#001528" : "#FFFFFA",
        text: themeMode === "dark" ? "#FFFFFA" : "#34404A",
        secondaryText: themeMode === "dark" ? "#cfd1ff" : "#3c3c4a",
        background: themeMode === "dark" ? "rgb(28, 30, 32)" : "#F8FAFC",
        contrastBackground:
          themeMode === "dark" ? "rgba(25, 27, 28, 0.5)" : "white",
        chartBackground: themeMode === "dark" ? "#202020" : "white",
        customSearch: themeMode === "dark" ? "#202020" : "#f1f5f9",
        customSearch2: themeMode === "dark" ? "transparent " : "#FFF",
        customSearchBorderColor:
          themeMode === "dark" ? "#E4E5E8 " : "transparent",
        boxShadow:
          themeMode === "dark"
            ? "0 -2px 10px rgba(0, 0, 0, 1)"
            : "0 0 10px #e9ebed !important",
        popBoxShadow:
          themeMode === "dark"
            ? "0 6px 17px 0 rgba(0, 0, 0, 0.25)"
            : "0 6px 17px 0 rgba(0, 46, 96, 0.09)",
        hover: themeMode === "dark" ? "#3A3A3D" : "#F5F5F5",
        seperator: themeMode === "dark" ? "#363637" : "#dee1e6",
        specialInput: themeMode === "dark" ? "#3A3A3D" : "white",
        slider: themeMode === "dark" ? "#3d3a3df2" : "#dfd0d073",
        specialBackground: themeMode === "dark" ? "#181818" : "white",
        orderBookGreen:
          themeMode === "dark" ? "rgba(173, 247, 164, 0.12)" : "#adf7a4cf",
        orderBookRed:
          themeMode === "dark" ? "rgba(134, 10, 10, 0.28)" : "#ffc4c4",
        appHeader: themeMode === "dark" ? "#2C302E" : "#F8FAFC",
        tabHeaderText: themeMode === "dark" ? "#FFFFFF" : "#A1A7BB",
        tableBackground:
          themeMode === "dark" ? "rgba(21, 23, 23, 0.5)" : "white",
        skeleton: themeMode === "dark" ? "#3A3A3D" : "lightgrey",
        skeletonBackground: themeMode === "dark" ? "#1a1717" : "white",
        tableHeaderText: themeMode === "dark" ? "white" : "grey",
        tableHeaderText2: themeMode === "dark" ? "#000000" : "white",
        searchBg: themeMode === "dark" ? "#202020" : "white",
        searchBg2: themeMode === "dark" ? "rgb(177 177 177 / 71%)" : "#F1F1F1",
        bb: themeMode === "dark" ? "rgb(60, 64, 67)" : "",
        movement: themeMode === "dark" ? "#3e444d" : "#F1F5F9",
        os:
          themeMode === "dark"
            ? "rgba(21, 23, 23, 0.5)"
            : "rgba(255, 255, 255, 0.5)",
        innerWrapper: themeMode === "dark" ? "rgb(1 0 2)" : "white",
        greyTitles: themeMode === "dark" ? "white" : "#64748B",
        description: themeMode === "dark" ? "white" : "#555555",
        tableHeader: themeMode === "dark" ? "white" : "#b7b4c7",
        unusual: themeMode === "dark" ? "white" : "#475569",
        select: themeMode === "dark" ? "grey" : "#eaeaea",
        svg: themeMode === "dark" ? "#cfd1ff" : "#3c3c4a",
        searchBorder:
          themeMode === "dark"
            ? "1px solid rgb(146,145,145)"
            : "1px solid white",
        // rgb(1 0 2) !important
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: "#0066ff",
        main: "#0044ff",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#ffcc00",
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
  });
  // set Theme mode from local storage
  useEffect(() => {
    const themeStorage = localStorage.getItem("theme");
    if (themeStorage) {
      themeToggle(themeStorage);
    } else {
      localStorage.setItem("theme", themeMode);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("theme", themeMode);
  }, [themeMode]);

  // set Layout mode from local storage
  useEffect(() => {
    const layoutStorage = localStorage.getItem("app_layout");
    if (layoutStorage) {
      layoutToggle(layoutStorage);
    } else {
      localStorage.setItem("app_layout", layoutMode);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("app_layout", layoutMode);
  }, [layoutMode]);
  function closeWelcomeMessage() {
    setUserSettings({
      ...userSettings,
      tgptWelcomeShowed: true,
    });
    setShowWelcomeMessage(false);
  }
  const showWelcomeJourney = useMemo(
    () =>
      !userSettings?.journey_completed &&
      !!userData &&
      !!authUser &&
      userData?.sign_in_attempts <= 2 &&
      moment(user?.metadata?.creationTime).isAfter("10/21/2023") &&
      !userData?.userSettings?.journey_completed &&
      pathname.includes("/home"),
    [userData, authUser, user, pathname, userSettings],
  );
  const showTip =
    showWelcomeMessage &&
    !!userData?.userSettings &&
    !userData?.userSettings.tgptWelcomeShowed &&
    canViewWidget &&
    !showChat;
  return (
    <ThemeProvider theme={theme}>
      {process.env.REACT_APP_GOOGLEANALYTICS && (
        <Helmet>
          <title>TradeAlgo</title>
          {/* <meta name="description" content="TradersAlgo is a fintech analytics company powering the open finance revolution. We democratize access to the world's most valuable financial data." /> */}
          <script
            async
            src={
              "https://www.googletagmanager.com/gtag/js?id=" +
              process.env.REACT_APP_GOOGLEANALYTICS
            }
          />
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '` +
              process.env.REACT_APP_GOOGLEANALYTICS +
              `');
            `}
          </script>
        </Helmet>
      )}
      {canViewChat && !showWidget && (
        <div
          style={{
            position: "fixed",
            top: "150px",
            right: "-2px",
            zIndex: 1000,
          }}
        >
          <div
            className="chat-div custom-chat"
            onClick={() => {
              if (userData?.display_name) {
                setShowWidget(false);
                setShowChat(true);
                cleanCount();
              } else {
                history.push("/settings/profile");
              }
            }}
          >
            <Badge
              overlap="circular"
              className="badge-icon"
              badgeContent={unreadMessageCount}
            >
              <img src={chatIcon} className="chat-icon" />
            </Badge>
          </div>
        </div>
      )}
      {showTip && (
        <div className="welcome-message-box">
          <div className="welcome-message-content">
            <div className="welcome-message-header">
              <div className="inside-header">
                <span className="welcome-new-badge">New</span>
                <span className="welcome-title"> Introducing TradeGPT </span>
              </div>
              <button
                className="welcome-close-btn"
                onClick={closeWelcomeMessage}
              >
                &times;
              </button>
            </div>
            <p className="welcome-description">
              Our smart AI trade assistant is here to guide you through your
              trading needs.
            </p>
          </div>
          <div className="welcome-carrot"></div>
        </div>
      )}

      {canViewWidget && !showChat && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1000,
          }}
        >
          <div
            className="chat-div"
            onClick={() => {
              setShowWidget((prev) => !prev);
              setShowChat(false);
              if (
                !userData?.userSettings?.tgptWelcomeShowed &&
                !userSettings?.tgptWelcomeShowed
              ) {
                closeWelcomeMessage();
              }
            }}
          >
            <img
              style={{ height: "21.33px", width: "19.9px" }}
              src={themeMode === "dark" ? logoWhite : logoBlack}
            />
            <div className="tradegpt-text">TradeGPT</div>
            <img
              src={themeMode === "dark" ? tgptArrowWhite : tgptArrow}
              className={`chat-icon ${showWidget ? "flip-vertical" : ""}`}
            />
          </div>
        </div>
      )}
      {!!nickName.length &&
        !!authUser &&
        !pathname.includes("live-chat") &&
        showChat && (
          <SendBirdProvider
            appId={appId}
            userId={authUser}
            nickname={nickName}
            stringSet={stringSet}
          >
            {!hideChat && (
              <div className={`sendbird-theme--${themeMode}  `}>
                <Conversation
                  onClose={() => {
                    setShowChat(false);
                    setTimeout(() => {
                      setHideChat(hideChat, liveChatExpanded);
                    }, 100);
                  }}
                  channelUrl={channelUrl}
                />
              </div>
            )}
          </SendBirdProvider>
        )}
      {!!authUser && showWidget && !hideChat && (
        <WidgetWrapper
          themeMode={themeMode}
          onClose={() => {
            setShowWidget(false);
            setTimeout(() => {
              setHideChat(hideChat, liveChatExpanded);
            }, 100);
          }}
        />
      )}
      {/* <DocusignModal /> */}
      {!isMobile && <InvestorSurvey />}
      {(showJourney || showWelcomeJourney) && !run && (
        <WelcomeModal
          user={userData}
          setJourneyViewed={setJourneyViewed}
          setShowJourney={setShowJourney}
          addUsername={addUsername}
          setRun={setRun}
        />
      )}
      {
        <Joyride
          styles={joyrideStyles}
          run={run}
          steps={homePageSteps}
          continuous
          hideCloseButton
          showSkipButton
          scrollToFirstStep
          callback={JourneyCallbacks}
        />
      }
      <Routes />
    </ThemeProvider>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  layoutMode: state.layoutMode.layoutMode,
  channels: state.Socket.channels,
  participants: state.chatParticipants.chatParticipants,
  messages: state.Socket.snackbarMessages,
  unreadMessageCount: state.Socket.unreadMessagesCount,
  hideChat: state.hideLiveChat.hideChat,
  liveChatExpanded: state.hideLiveChat.liveChatExpanded,
  darkflowTable: state.darkflowTable,
  userSettings: state.userSettings.userSettings,
  showJourney: state.runJourney.run,
});

const dispatchToProps = (dispatch) => ({
  themeToggle: (themeMode) => dispatch(themeSetter(themeMode)),
  layoutToggle: (layoutMode) => dispatch(layoutSetter(layoutMode)),
  listen: (channels) => dispatch(socketListen(channels)),
  cleanCount: () => dispatch(cleanUnreadMessageCount()),
  setHideChat: (open, liveChatExpanded) =>
    dispatch(liveChatSetter(open, liveChatExpanded)),
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
  addUsername: (username) => dispatch(addUserLoad(username)),
  setShowJourney: (run) => dispatch(runJourneySetter(run)),
  setUserSettings: (settings, firstRender, fetch) =>
    dispatch(addUserSettingsLoad(settings, firstRender, fetch)),
});

export default connect(stateToProps, dispatchToProps)(App);
